import React from 'react';
import '../style/ButtonApp.css';
export default class ButtonApp extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            id: '',
        }
    }
    render(){
        return(
            <button onClick={this.props.onClick} disabled={this.props.disabled} className='button-app'>{this.props.name}</button>
        );
    }
}