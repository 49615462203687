import React from 'react';
import * as IconsFa from "react-icons/fa";
import {connect} from "react-redux";
import Button from '../components/ButtonApp';
import '../App.css';
import AutocompleteApp from './AutocompleteApp';
import primaryValues from '../constants/Values.js';

const API_URL = `${process.env.REACT_APP_HOST_URL}${process.env.REACT_APP_API_ENDPOINT}`;

class VaucherImage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected_img: '',
            lang: 'Hebrew',
            images_names: this.props.initialState.images_names,
            data: {
                lang: 'he',
                url_image: null,
            }
        }
        this.props.fetchImagesNames({type: primaryValues.$GET_IMAGES_LIST});
    }

    onClick = () => {
        this.props.onClick(this.state.data);
    }
    changeState = (val, label, value) => {
        this.setState({ selected_img: value?.url ,data: { ...this.state.data, url_image: val.target.textContent }});
    }

    changeLang = (val, label, value) => {
        this.setState({ lang: val.target.textContent, data: { ...this.state.data, lang: value.id }});
    }

    getImageUrl() {
        switch (this.state.data.lang) {
            case 'he':
                return this.props.initialState.url_selected_image;
            case 'en':
                return this.props.initialState.url_selected_image_en;
            case 'el':
                return this.props.initialState.url_selected_image_el;
            default:
                return '';
        }
    }

    render() {
        return (
            <div>
                <h2 style={{textAlign: 'center', fontSize: 16, fontWeight: 'bold'}}>Change Voucher Image</h2>
                <AutocompleteApp icon={<IconsFa.FaGlobe
                    style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}} />}
                                 labelColor={'#2d2d2d'} defaultValue={this.state.data.lang}
                                 options={[{id: 'he', name: 'Hebrew'}, {id: 'en', name: 'English'}, {id: 'el', name: 'Greek'}]} label={'Language'}
                                 onChange={this.changeLang}
                />
                <hr />
                <div style={{ display: 'flex', flexDirection: 'row', gap: '15px' }}>
                    <div style={{ width: '450px' }}>
                        <div style={{ marginBottom: '20px' }}>
                            <h3 style={{ fontSize: 17, fontWeight: 'bold' }}>Set an image ({this.state.lang})</h3>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <AutocompleteApp vaucher={true} icon={<IconsFa.FaImages style={{
                                    color: '#2d2d2d',
                                    fontSize: 24,
                                    padding: 0,
                                    margin: 0,
                                    marginRight: 5
                                }} />}
                                                 labelColor={'#181818'} options={this.state.images_names} label={'Image'} onChange={this.changeState} />
                                <Button onClick={this.onClick} name="Set Image" />
                            </div>
                            {this.state.error_msg ? <p style={{color: 'red'}}>Selected image is must..</p> : ''}
                            {this.state.data.url_image ? <div><img src={process.env.REACT_APP_HOST_URL + '/' + this.state.selected_img} style={{
                                maxWidth: '200px',
                                height: 'auto'
                            }} alt="Image not exists" /></div> : ''}
                        </div>

                        <div>
                            <h3 style={{ fontSize: 17, fontWeight: 'bold', marginBottom: '15px' }}>Upload new image to DB</h3>
                            <form action={API_URL + 'fetchNewImage'} method="post" encType="multipart/form-data" style={{
                                display: 'flex',
                                gap: '10px',
                                alignItems: 'flex-start',
                                fontSize: 14,
                                flexDirection: 'column',
                            }}>
                                <input style={{fontWeight: 'bold'}} type="file" name="file" required />
                                <input style={{fontWeight: 'bold'}} placeholder="Image Name" type='text' name='name' required />
                                <Button type="submit" name="Upload Image" />
                            </form>
                        </div>
                    </div>
                    <div style={{ width: '450px', textAlign: 'center' }}>
                        <div>
                            <Button disabled={this.state.modalActive} onClick={() => {
                                window.open(API_URL + 'watchVaucher?lang=' + this.state.data.lang + '&order_id=id&name=name&club=club&price=price&address=address&people=people&date=date', '_blank');
                            }} name={`Watch Voucher (${this.state.lang})`} />
                        </div>
                        <div>
                            <img style={{ maxWidth: '100%', height: 'auto' }} src={process.env.REACT_APP_HOST_URL + '/' + this.getImageUrl()} alt="No Image" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        initialState: state.initialState,
    };
};

const mapDispachToProps = dispatch => {
    return {
        fetchImagesNames: (info) => dispatch(info)
    };
};

export default connect(mapStateToProps, mapDispachToProps)(VaucherImage);