import React from 'react';  import { connect } from "react-redux"; 
import  * as IconsFa  from "react-icons/fa";
import  * as IconsMi  from "react-icons/go";
import Input from '../components/InputApp';
import Button from '../components/ButtonApp';
import AutocompleteApp from './AutocompleteApp';
import primaryValues from '../constants/Values.js';
import { BsList } from "react-icons/bs";
export class NewClub extends React.Component{
  constructor(props){
    super(props);
    this.state={ 
      error_msg: 0,
      data:{
        club: '',
        name: '',
        address: '',
        phone: '',
        email: '',
        viber: '',
        whatsapp: '', 
        contactToDelte: '', 
      }
    }  
    this.changeState = this.changeState.bind(this);
  } 
  deleteClub = () =>{
      let params = {club: this.state.data.contactToDelte};
      if (window.confirm('deleteClub ' + this.state.data.contactToDelte + '?')) {
          this.props.fetchDeleteClub({ type: primaryValues.$GET_DELETE_CLUB, params}); 
        } 
        this.props.closeModal();
  }
  changeState = (val,label) => {
    switch(label){ 
        case 'Contact Title': //this.state.data.club = val.target.value;
          this.setState({data:{...this.state.data, club: val.target.value}})
        break;
        case 'Contact Name': //this.state.data.name = val.target.value;
          this.setState({data:{...this.state.data, name: val.target.value}})
        break;
        case 'Mobile':// this.state.data.phone = val.target.value;
          this.setState({data:{...this.state.data, phone: val.target.value}})
        break;
        case 'Contact Address':// this.state.data.phone = val.target.value;
          this.setState({data:{...this.state.data, address: val.target.value}})
        break;
        case 'Email': //this.state.data.email = val.target.value;
          this.setState({data:{...this.state.data, email: val.target.value}})
        break;
        case 'Viber':// this.state.data.viber = val.target.value;
          this.setState({data:{...this.state.data, viber: val.target.value}})
        break; 
        case 'Whatsapp':// this.state.data.whatsapp = val.target.value;
          this.setState({data:{...this.state.data, whatsapp: val.target.value}})
        break;
        case 'Contacts': //this.state.data.contactToDelte = val.target.textContent;
          let clubs = this.props.initialState.clubs 
          for(let i = 0;i < clubs.length;i++){
            if(clubs[i].name && clubs[i].name.toLowerCase() === val.target.textContent.toLowerCase()){ 
              this.setState({data:{...this.state.data, contactToDelte: val.target.textContent, club: val.target.textContent, address: clubs[i].address, name: clubs[i].contact, phone: clubs[i].phone, email: clubs[i].email, viber: clubs[i].viber, whatsapp: clubs[i].whatsapp}})  
              break; 
            } 
          }
          if(val.target.textContent === ''){
            this.setState({data:{...this.state.data, contactToDelte: '', club: '', address: '', name: '', phone: '', email: '', viber: '', whatsapp: ''}})    
          }
        break; 
        default:{ 
        }
    } 
}
onClick = () =>{
  this.setState({error_msg: 0});
  let data = this.state.data;
  let valuesFlag = false;
  for (const val in data) {  
    if(!data[val] && val !== 'contactToDelte')
      valuesFlag = true;
  } 
  if(valuesFlag){
  this.setState({error_msg: 1});
    //*console.log('valuesFlag');
  }else 
    this.props.onClick(this.state.data);
}
  render(){    
      return (   
            <div style={{width:'100%',height:'100%',textAlign:'center'}}>
                <p style={{color:'#181818',fontWeight:'bold',textAlign:'center',fontSize:14}}>New Contact Title</p>
                <Input disabled={this.state.data.contactToDelte} icon={<IconsFa.FaRegBuilding style={{color:'#2d2d2d',fontSize:24,padding:0,margin:0,marginRight:5 }}/>} 
                    inputColor={'#2d2d2d'} labelColor={'#2d2d2d'} value={this.state.data.club + ''} label={'Contact Title'} type={'text'} onChange={this.changeState} />
                <Input icon={<IconsMi.GoPerson style={{color:'#2d2d2d',fontSize:24,padding:0,margin:0,marginRight:5 }}/>} 
                    inputColor={'#2d2d2d'} labelColor={'#2d2d2d'} value={this.state.data.name + ''} label={'Contact Name'} type={'text'} onChange={this.changeState} />
                <Input icon={<IconsFa.FaRegAddressBook style={{color:'#2d2d2d',fontSize:24,padding:0,margin:0,marginRight:5 }}/>} 
                    inputColor={'#2d2d2d'} labelColor={'#2d2d2d'} value={this.state.data.address + ''} label={'Contact Address'} type={'text'} onChange={this.changeState} />
                <Input icon={<IconsMi.GoDeviceMobile style={{color:'#2d2d2d',fontSize:24,padding:0,margin:0,marginRight:5 }}/>} 
                    inputColor={'#2d2d2d'} labelColor={'#2d2d2d'} value={this.state.data.phone + ''} label={'Mobile'} type={'text'} onChange={this.changeState} />
                <Input icon={<IconsMi.GoMailRead style={{color:'#2d2d2d',fontSize:24,padding:0,margin:0,marginRight:5 }}/>} 
                    inputColor={'#2d2d2d'} labelColor={'#2d2d2d'} value={this.state.data.email + ''} label={'Email'} type={'email'} onChange={this.changeState} />
                <Input icon={<IconsFa.FaViber style={{color:'#2d2d2d',fontSize:24,padding:0,margin:0,marginRight:5 }}/>} 
                    inputColor={'#2d2d2d'} labelColor={'#2d2d2d'} value={this.state.data.viber + ''} label={'Viber'} type={'text'} onChange={this.changeState} />
                <Input icon={<IconsFa.FaWhatsapp style={{color:'#2d2d2d',fontSize:24,padding:0,margin:0,marginRight:5 }}/>} 
                    inputColor={'#2d2d2d'} labelColor={'#2d2d2d'} value={this.state.data.whatsapp + ''} label={'Whatsapp'} type={'text'} onChange={this.changeState} />
                <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                    <AutocompleteApp icon={<BsList style={{color:'#2d2d2d',fontSize:24,padding:0,margin:0,marginRight:5 }}/>} 
                      labelColor={'#2d2d2d'} value={''} options={this.props.initialState.clubs} label={'Contacts'} onChange={this.changeState} /> 
                </div>
                <p style={{opacity: this.state.error_msg,fontSize:14,color:'#9d1d1d',margin:0}}>All fields is must..</p>
                <Button onClick={this.onClick} name={'Save Contact'}/>
                <Button onClick={this.deleteClub} name={'Delete Contact'}/>   
            </div>   
      ) 
  }
} 
const mapStateToProps = state => {
  return {
    initialState: state.initialState,
  };
};
const mapDispachToProps = dispatch => {
  return {
    fetchDeleteClub: (info) => dispatch(info), 
  };
};
export default connect(mapStateToProps,mapDispachToProps)(NewClub);