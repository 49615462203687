import React from 'react';
import {connect} from "react-redux";
import * as IconsFa from "react-icons/fa";
import primaryValues from '../constants/Values.js';
import Input from '../components/InputApp';
import AutocompleteApp from './AutocompleteApp';
import Button from '../components/ButtonApp';
import {BsList} from "react-icons/bs";

export class NewUser extends React.Component {
    constructor(props) {
        super(props);
        this.emptyData = {
            id: 0,
            name: '',
            email: '',
            password: '',
            userToDelete: '',
        };

        this.state = {
            error_msg: 0,
            isUpdate: false,
            data: this.emptyData
        };

        this.changeState = this.changeState.bind(this);
    }

    changeState = (val, label) => {
        switch (label) {
            case 'Label Name': //this.state.data.name = val.target.value;
                this.setState({data: {...this.state.data, name: val.target.value}})
                break;
            case 'Email': //this.state.data.email = val.target.value;
                this.setState({data: {...this.state.data, email: val.target.value}})
                break;
            case 'Password':// this.state.data.password = val.target.value;
                this.setState({data: {...this.state.data, password: val.target.value}})
                break;
            case 'Users': //this.state.data.userToDelete = val.target.textContent;
                let users = this.props.initialState.users
                for (let i = 0; i < users.length; i++) {
                    if (users[i].name && users[i].name.toLowerCase() === val.target.textContent.toLowerCase()) {
                        this.setState({
                            isUpdate: true,
                            data: {
                                ...this.state.data,
                                userToDelete: val.target.textContent,
                                id: users[i].id,
                                name: users[i].name,
                                email: users[i].email,
                                password: '',
                            }
                        })
                        break;
                    }
                }
                if (val.target.textContent === '') {
                    this.setState({isUpdate: false, data: this.emptyData})
                }
                break;
            default:
                break;
        }
    }
    deleteUser = () => {
        let params = {user: this.state.data.userToDelete};
        if (window.confirm('Delete User ' + this.state.data.userToDelete + '?')) {
            this.props.fetchDeleteUser({type: primaryValues.$GET_DELETE_USER, params});
            this.setState({ isUpdate: false, data: this.emptyData });
        }
        this.props.closeModal();
    }
    onClick = () => {
        this.setState({error_msg: 0});
        let data = Object.assign({}, this.state.data);
        let valuesFlag = false;

        if (this.state.isUpdate) {
            if (!(data.email && data.name)) {
                valuesFlag = true;
            }
        } else {
            delete data.id;
            for (const val in data) {
                if (!data[val] && val !== 'userToDelete') {
                    valuesFlag = true;
                }
            }
        }

        if (valuesFlag) {
            this.setState({error_msg: 1});
        } else {
            this.props.onClick(this.state.data);
        }
    }

    render() {
        return (
            <div style={{width: '100%', height: '100%', textAlign: 'center'}}>
                <p style={{color: '#181818', fontWeight: 'bold', textAlign: 'center', fontSize: 14}}>New System User</p>
                <Input disabled={this.state.data.userToDelete} icon={<IconsFa.FaUserTag style={{
                    color: '#2d2d2d',
                    fontSize: 24,
                    padding: 0,
                    margin: 0,
                    marginRight: 5
                }}/>}
                       inputColor={'#2d2d2d'} labelColor={'#2d2d2d'} value={this.state.data.name + ''} label={'Label Name'} type={'text'} onChange={this.changeState}/>
                <Input icon={<IconsFa.FaUserTag style={{
                    color: '#2d2d2d',
                    fontSize: 24,
                    padding: 0,
                    margin: 0,
                    marginRight: 5
                }}/>}
                       inputColor={'#2d2d2d'} labelColor={'#2d2d2d'} value={this.state.data.email + ''} label={'Email'} type={'email'} onChange={this.changeState}/>
                <Input icon={<IconsFa.FaUserLock style={{
                    color: '#2d2d2d',
                    fontSize: 24,
                    padding: 0,
                    margin: 0,
                    marginRight: 5
                }}/>}
                       inputColor={'#2d2d2d'} labelColor={'#2d2d2d'} value={this.state.data.password + ''} label={'Password'} type={'text'} onChange={this.changeState}/>
                {/* <Input icon={<IconsFa.FaUserLock style={{color:'#2d2d2d',fontSize:24,padding:0,margin:0,marginRight:5 }}/>} 
                    inputColor={'#2d2d2d'} labelColor={'#2d2d2d'} value={this.state.data.retype_password + ''} label={'Retype Password'} type={'text'} onChange={this.changeState} /> */}
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <AutocompleteApp icon={<BsList style={{
                        color: '#2d2d2d',
                        fontSize: 24,
                        padding: 0,
                        margin: 0,
                        marginRight: 5
                    }}/>}
                                     labelColor={'#2d2d2d'} value={''} options={this.props.initialState.users} label={'Users'} onChange={this.changeState}/>
                    {/* <p style={{opacity: this.state.userToDelete !== '' ? 0 : 1,fontSize:14,color:'#9d1d1d',margin:0}}>{this.state.userToDelete}</p> */}
                </div>
                <p style={{
                    opacity: this.state.error_msg,
                    fontSize: 14,
                    color: '#9d1d1d',
                    margin: 0
                }}>All fields is must..</p>
                <Button onClick={this.onClick} name={'Save User'}/>
                <Button disabled={!this.state.isUpdate} onClick={this.deleteUser} name={'Delete User'}/>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        initialState: state.initialState,
    };
};
const mapDispachToProps = dispatch => {
    return {
        fetchDeleteUser: (info) => dispatch(info),
    };
};
export default connect(mapStateToProps, mapDispachToProps)(NewUser);