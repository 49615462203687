import React from 'react';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Helmet } from 'react-helmet'

const TITLE = 'Greek Nights'

export default class App extends React.Component{
  render(){
    return (
      <Router>
        <div>
        <Helmet>
          <title>{ TITLE }</title>
        </Helmet>
          <Route exact path="/">
            <Login />
          </Route>
          <Route path="/Dashboard">
            <Dashboard />
          </Route>
          {/* <Route path="/Login">
            <Login />
          </Route> */}
        </div>
      </Router>
    );
  }
}


