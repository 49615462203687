import { takeLatest } from "redux-saga/effects";
import  * as sagas from "./sagas.js";
import primaryValues from '../constants/Values.js';
export function* rootSaga() {
  yield takeLatest(primaryValues.$GET_LOGIN, sagas.fetchLogin);
  yield takeLatest(primaryValues.$GET_TOKEN, sagas.fetchUserToken);
  yield takeLatest(primaryValues.$GET_ORDERS, sagas.fetchOrders);
  yield takeLatest(primaryValues.$GET_CLUBS, sagas.fetchClubs); 
  yield takeLatest(primaryValues.$GET_WHATSAPP_MESSEAGE, sagas.fetchWhatsappMessage);
  yield takeLatest(primaryValues.$GET_GMAIL_MESSEAGE, sagas.fetchGmailMessage);
  yield takeLatest(primaryValues.$GET_UPDATE_ORDER, sagas.fetchUpdateOrder);
  yield takeLatest(primaryValues.$GET_DELETE_ORDER, sagas.fetchDeleteOrder);
  yield takeLatest(primaryValues.$GET_NEW_CLUB, sagas.fetchNewClub);
  yield takeLatest(primaryValues.$GET_NEW_ORDER, sagas.fetchNewOrder);
  yield takeLatest(primaryValues.$GET_NEW_USER, sagas.fetchNewUser);
  yield takeLatest(primaryValues.$GET_EDIT_PAGE_INDEX, sagas.fetchEditPageIndex);
  yield takeLatest(primaryValues.$GET_USERS, sagas.fetchUsersCompany);
  yield takeLatest(primaryValues.$GET_EXCEL_FILE, sagas.fetchExcelFile);
  yield takeLatest(primaryValues.$GET_IMAGES_LIST, sagas.fetchImagesNames);
  yield takeLatest(primaryValues.$GET_NEW_IMAGE, sagas.fetchNewVaucherImage);
  yield takeLatest(primaryValues.$GET_LAST_ROW_EDIT_ID, sagas.fetchLastEditRowId);
  yield takeLatest(primaryValues.$GET_SMS_MESSAGE, sagas.fetchSmsMessage);
  yield takeLatest(primaryValues.$GET_DELETE_USER, sagas.fetchDeleteUser);
  yield takeLatest(primaryValues.$GET_DELETE_CLUB, sagas.fetchDeleteClub);
  yield takeLatest(primaryValues.$GET_SEND_VAUCHER_CUSTOMER, sagas.fetchSendVaucherToCustomer);
}