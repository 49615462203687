export const primaryTheme = {
    modal_loading: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 1000,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modal_default: {
        backgroundColor: '#181818',
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        padding: 10,
        fontSize: 14,
        zIndex: 25,
    },
    modal_default_out_view: {
        zIndex: 20,
        width: '100vw',
        height: '100vh',
        position: 'absolute',
        top: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0,0)',
    },
    modal_default_inside_view: {
        zIndex: 90,
        width: '100vw',
        height: '100vh',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // paddingBottom:100,
        backgroundColor: 'rgba(0,0,0,0.7)',
    },
    modal_default_input: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    }
}

export default primaryTheme;