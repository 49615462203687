import React from 'react';
import * as Icons from "react-icons/md";
import * as IconsFa from "react-icons/fa";
import * as IconsMi from "react-icons/go";
import * as IconsIo from "react-icons/io";
import {connect} from "react-redux";
import Input from '../components/InputApp';
import Button from '../components/ButtonApp';
import AutocompleteApp from './AutocompleteApp';
import DatePick from '../components/DatePick';
import {modalServices} from '../services';
import {Checkbox, FormControlLabel} from "@material-ui/core";

class NewOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: this.props.initialState.users,
            error_msg: '',
            data: {
                date: '',
                club: '',
                notes: '',
                admin_notes: '',
                people: '',
                name: '',
                mobile: '',
                send_mobile: 'No',
                email: '',
                received_by: 'Office',
                confirmed: 'waiting',
                who_put: '',
                transfers: '',
                google: '',
                vip: '',
                vaucher: '',
                lang: 'he',
            }
        }
        this.changeState = this.changeState.bind(this);
    }

    componentDidMount() {
        if (modalServices.copyOrderData !== null) {
            this.setState({data: modalServices.copyOrderData}, () => modalServices.setCompyOrderData(null))
        }
    }

    shouldComponentUpdate() {
        if (!modalServices.isNew && ((this.props.copyOrderData && modalServices.copyOrderData) || (modalServices.copyOrderData && !this.props.copyOrderData))) {
            const data = modalServices.copyOrderData ? modalServices.copyOrderData : this.props.copyOrderData
            modalServices.setCompyOrderData(null)
            this.setState({data})
        }
        return true
    }

    changeState = (val, label, value) => {
        // alert(val)
        switch (label) {
            case 'Date': //this.state.data.date = val.target.value;
                this.setState({data: {...this.state.data, date: val}})
                break;
            case 'Order Contact': //this.state.data.club = val.target.value;
                this.setState({data: {...this.state.data, club: val.target.value}})
                break;
            case 'Notes': //this.state.data.notes = val.target.value;
                this.setState({data: {...this.state.data, notes: val.target.value}})
                break;
            case 'Admin Notes':
                this.setState({data: { ...this.state.data, admin_notes: val.target.value}})
                break;
            case 'People': //this.state.data.people = val.target.value;
                this.setState({data: {...this.state.data, people: val.target.value}})
                break;
            case 'Name': //this.state.data.name = val.target.value;
                this.setState({data: {...this.state.data, name: val.target.value}})
                break;
            case 'Mobile':// this.state.data.mobile = val.target.value;
                this.setState({data: {...this.state.data, mobile: val.target.value}})
                break;
            case 'Send Mobile':// this.state.data.mobile = val.target.value;
                this.setState({data: {...this.state.data, send_mobile: val.target.checked ? 'Yes' : 'No' }})
                break;
            case 'Email':// this.state.data.email = val.target.value;
                this.setState({data: {...this.state.data, email: val.target.value}})
                break;
            case 'Transfers': //this.state.data.transfers = val.target.textContent;
                this.setState({data: {...this.state.data, transfers: val.target.textContent}})
                break;
            case 'Received By': //this.state.data.received_by = val.target.textContent;
                this.setState({data: {...this.state.data, received_by: val.target.textContent}})
                break;
            case 'Confirmed': //this.state.data.confirmed = val.target.textContent === 'Waiting' ? 'waiting' : val.target.textContent === 'Sent' ? 'padding' : 'approved';
                this.setState({
                    data: {
                        ...this.state.data,
                        confirmed: val.target.textContent === 'Waiting' ? 'waiting' : val.target.textContent === 'Sent' ? 'padding' : 'approved'
                    }
                })
                break;
            case 'Who Put': //this.state.data.who_put = val.target.textContent;
                this.setState({data: {...this.state.data, who_put: val.target.textContent}})
                break;
            case 'Google': //this.state.data.google = val.target.textContent;
                this.setState({data: {...this.state.data, google: val.target.textContent}})
                break;
            case 'Vip': //this.state.data.vip = val.target.textContent;
                this.setState({data: {...this.state.data, vip: val.target.textContent}})
                break;
            case 'Vaucher': //this.state.data.vaucher = val.target.textContent;
                this.setState({data: {...this.state.data, vaucher: val.target.textContent}})
                break;
            case 'Language':
                this.setState({data: {...this.state.data, lang: value.id}})
                break;
            default: {

            }
        }
    }
    onClick = () => {
        this.setState({error_msg: ''});
        let data = this.state.data;
        const valuesFlag = [];

        for (const val in data) {
            // Skip those fields:
            if (['who_put', 'transfers', 'google', 'vip', 'vaucher', 'notes', 'admin_notes'].includes(val)) {
                continue;
            }

            if (!data[val]) {
                valuesFlag.push(val.replaceAll('_', ' '));
            }
        }

        if (valuesFlag.length > 0) {
            this.setState({error_msg: 'The following fields are required: ' + valuesFlag.join(', ')});
        } else {
            this.props.onClick(this.state.data);
        }
    }

    render() {
        return (
            <div id={'new-order-modal'}
                 style={{width: '100%', flexDirection: 'column', display: 'flex', zIndex: 99999}}>
                <p style={{fontSize: 14, color: '#181818', fontWeight: 'bold', textAlign: 'center'}}>New Order</p>
                <div id="add-order"
                     style={{display: 'flex', flexDirection: 'row', alignSelf: 'center', justifyContent: 'center'}}>
                    <div>
                        <DatePick onChange={this.changeState}/>
                        {/* <Input icon={<IconsFa.FaRegCalendarAlt style={{color:'#2d2d2d',fontSize:24,padding:0,margin:0,marginRight:5 }}/>}
                          inputColor={'#2d2d2d'} labelColor={'#2d2d2d'} value={this.state.data.date + ''} label={'Date'} type={'text'} onChange={this.changeState} /> */}
                        <Input icon={<IconsFa.FaRegBuilding
                            style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                               inputColor={'#2d2d2d'} labelColor={'#2d2d2d'} value={this.state.data.club + ''}
                               label={'Order Contact'} type={'text'} onChange={this.changeState}/>
                        <Input icon={<IconsMi.GoNote
                            style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                               inputColor={'#2d2d2d'} labelColor={'#2d2d2d'} value={this.state.data.notes + ''}
                               label={'Notes'} type={'text'} onChange={this.changeState}/>
                        <Input icon={<IconsIo.IoIosPeople
                            style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                               inputColor={'#2d2d2d'} labelColor={'#2d2d2d'} value={this.state.data.people + ''}
                               label={'People'} type={'text'} onChange={this.changeState}/>
                    </div>
                    <div>
                        <Input icon={<IconsMi.GoPerson
                            style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                               inputColor={'#2d2d2d'} labelColor={'#2d2d2d'} value={this.state.data.name + ''}
                               label={'Name'} type={'text'} onChange={this.changeState}/>
                        <Input icon={<IconsMi.GoDeviceMobile
                            style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                               inputColor={'#2d2d2d'} labelColor={'#2d2d2d'} value={this.state.data.mobile + ''}
                               label={'Mobile'} type={'text'} onChange={this.changeState}/>
                        <Input>
                            <FormControlLabel
                                value="Yes"
                                control={<Checkbox checked={this.state.data.send_mobile === 'Yes'} />}
                                label="Send Mobile"
                                labelPlacement="end"
                                onChange={e => {this.changeState(e, 'Send Mobile')}}
                            />
                        </Input>
                        <Input icon={<IconsMi.GoMail
                            style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                               inputColor={'#2d2d2d'} labelColor={'#2d2d2d'} value={this.state.data.email + ''}
                               label={'Email'} type={'email'} onChange={this.changeState}/>
                        <label style={{color: '#2d2d2d',
                            margin: '10px 10px -5px',
                            fontSize: '13px'
                        }}>Admin Notes</label>
                        <Input icon={<IconsMi.GoNote
                            style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                               inputColor={'#2d2d2d'} labelColor={'#2d2d2d'} value={this.state.data.admin_notes + ''}
                               label={'Admin Notes'} type={'text'} onChange={this.changeState}/>
                    </div>
                    <div>
                        <AutocompleteApp icon={<Icons.MdDirectionsTransit
                            style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                                         labelColor={'#2d2d2d'} options={[{name: 'Yes'}, {name: 'No'}]}
                                         label={'Transfers'} onChange={this.changeState}/>
                        <AutocompleteApp defaultValue={`Office`} icon={<IconsIo.IoIosSave
                            style={{color: '#181818', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                                         labelColor={'#2d2d2d'} options={[{name: 'System'}, {name: 'Office'}]}
                                         label={'Received By'} onChange={this.changeState}/>
                        <AutocompleteApp defaultValue={`waiting`} icon={<IconsFa.FaBookOpen
                            style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                                         labelColor={'#2d2d2d'}
                                         options={[{name: 'Waiting'}, {name: 'Sent'}, {name: 'Aprovmed'}]}
                                         label={'Confirmed'} onChange={this.changeState}/>
                        <AutocompleteApp icon={<IconsFa.FaPencilAlt
                            style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                                         labelColor={'#2d2d2d'} options={this.state.users} label={'Who Put'}
                                         onChange={this.changeState}/>
                    </div>
                    <div>
                        <AutocompleteApp icon={<IconsFa.FaGoogle
                            style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                                         labelColor={'#2d2d2d'} options={[{name: 'Yes'}, {name: 'No'}]} label={'Google'}
                                         onChange={this.changeState}/>
                        <AutocompleteApp icon={<IconsIo.IoIosStar
                            style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                                         labelColor={'#2d2d2d'} options={[{name: 'Yes'}, {name: 'No'}]} label={'Vip'}
                                         onChange={this.changeState}/>
                        <AutocompleteApp icon={<Icons.MdSdCard
                            style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                                         labelColor={'#2d2d2d'} options={[{name: 'Yes'}, {name: 'No'}]}
                                         label={'Vaucher'} onChange={this.changeState}/>
                        <AutocompleteApp icon={<IconsFa.FaGlobe
                            style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                                         labelColor={'#2d2d2d'}
                                         options={[{id: 'he', name: 'Hebrew'}, {id: 'en', name: 'English'}, {
                                             id: 'el',
                                             name: 'Greek'
                                         }]}
                                         defaultValue={this.state.data.lang} label={'Language'}
                                         onChange={this.changeState} />
                    </div>
                </div>
                {this.state.error_msg && <p style={{fontSize: 14, color: '#9d1d1d', margin: 0}}>{this.state.error_msg}</p>}
                <Button onClick={this.onClick} name="Save New Order" />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        initialState: state.initialState,
    };
};
const mapDispachToProps = dispatch => {
    return {};
};
export default connect(mapStateToProps, mapDispachToProps)(NewOrder);