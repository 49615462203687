const _modalService = () => {
    return({
        copyOrderData:null,
        isNew: false,
        newOrder(){

        },
        closeNewOrderModalModal(){

        },
        openNewOrderModal(){

        },
        setCompyOrderData(data){
            this.copyOrderData = data
        }
    })
} 

export default _modalService