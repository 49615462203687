import React from 'react';
import loginImage from '../style/loginImage.jpg';
import { FaUserTag, FaUserLock } from 'react-icons/fa';
import '../App.css';
import Input from '../components/InputApp.js';
import Button from '../components/ButtonApp.js';
import Modal from '../components/ModalApp.js';
import primaryValues from '../constants/Values.js';
import { connect } from "react-redux";
import { Redirect } from 'react-router';
import {Route} from "react-router-dom";

class Login extends React.Component{
  constructor(props){
    super(props);
    this.state={
      email: '',
      password: '',
      isLogout: true
    }
    this.updateInputEmail = this.updateInputEmail.bind(this);
    this.updateInputPassword = this.updateInputPassword.bind(this);
  }
  componentDidMount(){
    const email = this.getFromLS("@email")
    const password = this.getFromLS("@password") 

    if(email && !this.props.initialState.login_success)
      this.setState({email: email},()=>{
        if(password)
          this.setState({password: password},()=>{ 
            this.fetchLogin();
          }) 
      })
  }

  updateInputEmail = (event,name) => {
    this.setState({email: event.target.value},()=>this.saveToLS("@email", this.state.email)); 
  }

  updateInputPassword = (event,name) => {
    this.setState({password: event.target.value},()=>this.saveToLS("@password", this.state.password)); 
  }

  fetchLogin = () =>{
    this.setState({isLogout: false})
    let params = {
      email: this.state.email,
      password: this.state.password,
    }
    this.props.fetchLogin({ type: primaryValues.$GET_LOGIN, params });
  }

  fetchLogout = (history) => {
    this.removeFromLSO('@email');
    this.removeFromLSO('@password');
    let params = {
      login_success: false,
    };
    this.props.fetchLogout({ type: primaryValues.$GET_LOGOUT, params });
    history.push('/');
  }

  LogoutButton = () => (
      <div style={{maxWidth: 200}}>
        <Route render={({history}) => (
            <button onClick={() => {
              this.fetchLogout(history);
            }}>
              Logout
            </button>
        )}/>
      </div>
  )
  
  getFromLS(key) {
    let ls = {};
    if (global.localStorage) {
      try {
        ls = JSON.parse(global.localStorage.getItem(key)) || {};
      } catch (e) {
        /*Ignore*/
      }
    }
    return ls[key];
  }
  
  removeFromLSO(key) { 
    if (global.localStorage) {
      try {
          global.localStorage.removeItem(key) 
      } catch (e) {
        /*Ignore*/
      }
    } 
  }

  saveToLS(key, value) {
    if (global.localStorage) {
      global.localStorage.setItem(
        key,
        JSON.stringify({
          [key]: value
        })
      );
    }
  } 

  render(){
    
    if (this.props.initialState.login_success && !this.state.isLogout) {
      return <Redirect to={{pathname: '/Dashboard'}}/>;
    }

    if (this.props.logout) {
      return this.LogoutButton();
    }

    return (
      <div className="App">  
          {this.props.initialState.loading_login === true ?  <Modal name={'loading'} title_name={'Loading Site..'}/>: null}
          <form className="main-view" onSubmit={(e) => {e.preventDefault(); this.fetchLogin()}}>
            <Input icon={<FaUserTag style={{color:'#2d2d2d',fontSize:24,padding:0,margin:0,marginRight:5 }}/>} inputColor={'#e8f0fe'} labelColor={'#e8f0fe'} value={this.state.email} label={'Email'} type={'text'} onChange={this.updateInputEmail} />
            <Input icon={<FaUserLock style={{color:'#2d2d2d',fontSize:24,padding:0,margin:0,marginRight:5 }}/>} inputColor={'#e8f0fe'} labelColor={'#e8f0fe'} value={this.state.password} label={'Password'} type={'password'} onChange={this.updateInputPassword}/>
            <p className='error-text-app' style={{padding:'0px',margin:'0px',fontSize:'13px',fontWeight:'bold'}}>{this.props.initialState.error_msg}</p>
            <Button type="submit" name={'Sign In'}/>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'center',position:'absolute',bottom:5}}>
              <a href={'mailto: avishaytl@gmail.com'} style={{fontSize:'12px' ,color:'rgba(255,255,255,0.5)',margin:0, letterSpacing: 1}}>support - avishaytl@gmail.com - {`${process.env.REACT_APP_VERSION}`}</a> 
            </div>
          </form>
          <img className="App-logo" src={process.env.REACT_APP_HOST_URL + '/public/images/logoE.png'} style={{width:600}} alt='logo'/>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    initialState: state.initialState,
  };
};
const mapDispachToProps = dispatch => {
  return {
    fetchLogin: (info) => dispatch(info),
    fetchLogout: (info) => dispatch(info),
  };
};
export default connect(mapStateToProps,mapDispachToProps)(Login);